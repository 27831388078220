html{
  height:100%;
  margin: 0;
}
body {
  margin: 0;
  text-align: center;
  font-size: 1em;
  font-family: "Libre Franklin";
  position: relative;
  height: 100%;
}
.App{
  height: 100%;
}
#root{
  height: 100%;
}
@font-face {
    font-family: "AbrilFatface";
    src: url("../public/fonts/AbrilFatface-Regular.otf");
}
@font-face {
    font-family: "AbhayaLibre";
    src: url("../public/fonts/AbhayaLibre-Regular.ttf");
}
@font-face {
  font-family: "Libre Franklin";
  src: url("../public/fonts/LibreFranklin-VariableFont_wght.ttf");
}
h1 {
  font-size: min(8vw,2em);
  color: #000000;
  font-family: "AbrilFatface";
  font-stretch: condensed;
  display: inline;
  text-align: center;
}
.header-div{
  border-bottom: 1px solid #eee;
  padding: 10px 0px 10px 80px;
  margin: 0 0 15px 0;
  text-align: center;
}
@media only screen and (max-width: 330px) {
  .header-div{
    border-bottom: 1px solid #eee;
    padding-left: 0px;
    margin: 0 0 15px 0;
    text-align: center;
  }
}
#game-container{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
}
#grid-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
#grid-div{
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  padding: 10px;
  box-sizing: border-box;
  
  height: 420px;
}
.row{
  display: flex;
  width: 100%;
  touch-action: manipulation;
  vertical-align: baseline;
  padding:0;
  border:0;
  grid-gap: 5px;
}
.row > div {
  border: 2px solid #d3d6da;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  line-height: 1;
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  flex: 1 1;
}
.row > div.tile-container-green {
  --background: #6aaa64;
  --border-color: #6aaa64;
  animation: flip 0.5s ease forwards;
}
.row > div.tile-container-grey {
  --background: #787e7c;
  --border-color: #787e7c;
  animation: flip 0.5s ease forwards;
}
.row > div.tile-container-yellow {
  --background: #c9b458;
  --border-color: #c9b458;
  animation: flip 0.5s ease forwards;
}
.row > div:nth-child(2) {
  animation-delay: 0.2s;
}
.row > div:nth-child(3) {
  animation-delay: 0.3s;
}
.row > div:nth-child(4) {
  animation-delay: 0.4s;
}
.row > div:nth-child(5) {
  animation-delay: 0.5s;
}
.row > div:nth-child(6) {
  animation-delay: 0.6s;
}
.row > div:nth-child(7) {
  animation-delay: 0.7s;
}
.row > div:nth-child(8) {
  animation-delay: 0.8s;
}
.row > div:nth-child(9) {
  animation-delay: 0.9s;
}
.row > div:nth-child(10) {
  animation-delay: 1s;
}
.row > div:nth-child(11) {
  animation-delay: 1.1s;
}
.row > div:nth-child(12) {
  animation-delay: 1.2s;
}
.row > div:nth-child(13) {
  animation-delay: 1.3s;
}
.row > div:nth-child(14) {
  animation-delay: 1.4s;
}
.row > div:nth-child(15) {
  animation-delay: 1.5s;
}
.row > div:nth-child(16) {
  animation-delay: 1.6s;
}
.row > div:nth-child(17) {
  animation-delay: 1.7s;
}
.row > div:nth-child(18) {
  animation-delay: 1.8s;
}
.row > div:nth-child(19) {
  animation-delay: 1.9s;
}

.row > .space-div {
  border: 0px solid #d3d6da;
  flex: 0.3;
}
@keyframes flip {
  0% {
    transform: rotateX(0);
    background: #fff;
    border-color: #000000;
  }
  45% {
    transform: rotateX(90deg);
    background: #fff;
    border-color: #000000;
  }
  55% {
    transform: rotateX(90deg);
    background: var(--background);
    border-color: var(--border-color);
    color: #eee;
  }
  100% {
    transform: rotateX(0);
    background: var(--background);
    border-color: var(--border-color);
    color: #eee;
  }
}

.row.current > div.tile-container-filled {
  animation: bounce 0.2s ease-in-out forwards;
}

.keypad {
  height: 200px;
  margin: 0 8px;
  vertical-align: baseline;
  padding:0;
  border:0;
  
}
.keypad-row{
  display: flex;
  width: 100%;
  margin: 0 auto 8px;
  touch-action: manipulation;
  vertical-align: baseline;
  padding:0;
  border:0;
}
@media (max-width: 700px) {
  .in-modal {
    max-width: 300px;
    background: #fff;
    padding: 20px 20px;
    border-radius: 10px;
    margin: 70px auto 0px auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.73);
  }
}
.keypad-row > div {
  font-weight: bold;
  font-size: 1.25em;
  border: 0;
  padding: 0;
  margin: 0 6px 0 0;
  height: 58px;
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #d3d6da;
  color: #000;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, .3);
}
.keypad-row > div:active {
  background-color: rgba(219, 216, 216, 0.933);
}

.keypad-row > div.green {
  background: #6aaa64;
  color: #fff;
  transition: all 0.3s ease-in;
  transition-delay: 1s;
}
.keypad-row > div.green:active {
  background-color: #366531;
}
.keypad-row > div.yellow {
  background: #c9b458;
  color: #fff;
  transition: all 0.3s ease-in;
  transition-delay: 1s;
}
.keypad-row > div.yellow:active {
  background-color: #c3b05b;
}
.keypad-row > div.grey {
  background: #787e7c;
  color: #fff;
  transition: all 0.3s ease-in;
  transition-delay: 1s;
}
.keypad-row > div.grey:active {
  background-color: #6c6b6b;
}

.modal {
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (min-width: 700px) {
.modal .in-modal {
  max-width: 380px;
  background: #fff;
  padding: 20px 40px;
  border-radius: 10px;
  margin: 70px auto 0px auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.73);
}
}
.modal .solution {
  color: #ff004c;
  font-size: 0.8em;
  text-transform: capitalize;
  letter-spacing: 1px;
}
/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
tr td:first-child {
  width: 1%;
  white-space: nowrap;
}
.barChart {
  background-color: rgb(107, 107, 109);
  width: 430px;
  color: white;
}

.barChartCurrentGame {
  background-color: #6aaa64;
  width: 430px;
}
h5 {
  padding: 5px 0;
  margin: 5px;
  text-align: left;
  text-transform: uppercase;
}

.horizontal-line {
  border-top: 1.5px solid #d3d6da;
  margin: 10px;
}

.modal-image {
  height: 30px;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-text {
  margin: 1px;
  margin-left: 10px;
  text-align: left;
}

.restart-button {
  background-color: #6aaa64;
  border: 2px solid white;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 36px;
  transition-duration: 0.4s;
}
.restart-button:hover {
  background-color: white;
  border: 2px solid #6aaa64;
  color: black;
}
.gameStat {
  margin-right: auto;
  width: 70%
}

.gameStatNum {
  font-size: 2em;
}

.gameStatText {
  font-size: 0.7em;
}

.landing {
  background: #e3e3e1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.in-landing {
  max-width: 480px;
  padding: 20px 40px;
  margin: 3% auto;
}

.landing h1 {
  padding: 0px 0 0 0;
  color: black;
  border-bottom: 0px;
  font-family: "AbrilFatface";;
  font-size: min(10vw,2.5em);
  font-stretch: condensed;
  font-weight: 100;
  margin: 10px;
}

.landing h2 {
  padding: 0px 0 0 0;
  margin: 10px;
  font-size: min(8vw,2em);
  font-family: "AbhayaLibre";
  font-weight: 100;
}

.landing p {
  font-size: 1em;
  font-weight: 200;
}

.landing button {
  background-color: transparent;
  border: 2px solid black;
  color: black;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-weight: 300;
  margin: 20px 20px;
  cursor: pointer;
  border-radius: 36px;
  transition-duration: 0.4s;
  width: min(50vw,200px);
}

.landing button:hover {
  background-color: black;
  border: 2px solid black;
  color: white;
}

.landing .button-black {
  background-color: black;
  border: 2px solid black;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-weight: 300;
  margin: 20px 20px;
  cursor: pointer;
  border-radius: 36px;
  transition-duration: 0.4s;
  width: min(50vw,200px);
}

.landing .button-black:hover {
  background-color: transparent;
  border: 2px solid black;
  color: black;
}

.landing img {
  width: 15%;
  height: 30%;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    border-color: #ddd;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    border-color: #000000;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.row.current.shaky {
  animation: horizontal-shaking 0.35s;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  15% { transform: translateX(5px) }
  30% { transform: translateX(-5px) }
  45% { transform: translateX(5px) }
  60% { transform: translateX(0) }
  75% { transform: translateX(5px) }
  90% { transform: translateX(-5px) }
  100% { transform: translateX(5px) }
 }

.same-word-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 3;
  position: relative;
}